@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

$footer-height : 76px;


body {
  background: url(../img/frozen_woods.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: $footer-height;
  margin-top: $footer-height;
}

.super-heading {
	h1 { 
		font-family: $smallerHeadingFont;
		color: rgba(0,0,0,1.0);
	}
}

.big-box {
	background-color : rgba(0,0,0,0.4);
	color: white;
	h1 {
		font-family: $smallerHeadingFont;
		color: white;
	}
	h3 {
		font-family: $body-font-family;
		color: rgba(0,0,0,0.8)
	}

	img {
		padding: 1rem;
		border-radius: 6%;
		@include breakpoint(small) {
			max-width: 95%;
		}
	}

	a {
		color: white;
		@include breakpoint(small) {
			font-size: 8pt;
		}

		@include breakpoint(medium) {
	      font-size: 14pt;

		}
	}


}
.footer{
	text-align: center;
    position:fixed;
    height:$footer-height;
    background-color:rgba(25,25,25,0.5);
    bottom:0px;
    left:0px;
    right:0px;
    margin-bottom:0px;
    color: white;
    padding-top: 13px;
    font-size: 14pt;
	
	@include breakpoint(small) {
		font-size: 10pt;
	}

	@include breakpoint(medium) {
      font-size: 14pt;

	}
	
    a {
    	color: white;
    }
}

.footer-logo {
	img {
		float: left;
		min-height: 50px;
		min-width: 50px;
		margin-right: 20px;
	}
}